import { loadStripe } from "@stripe/stripe-js/pure";
import { StripeElements, StripeElement } from "vue-stripe-js";
import { useConfigStore } from "~/store/config";

export default defineNuxtPlugin(async (nuxtApp) => {
  const { env } = useConfigStore();

  const stripe = await loadStripe(env.STRIPE_KEY, {
    locale: "fr",
  });

  nuxtApp.vueApp.component("StripeElements", StripeElements);
  nuxtApp.vueApp.component("StripeElement", StripeElement);

  return {
    provide: {
      stripe,
    },
  };
});
