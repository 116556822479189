import { useAuthStore } from "~/store/auth";
import { useConfigStore } from "~/store/config";

export default defineNuxtPlugin(async ({ $api }) => {
  const token = localStorage.getItem("token");

  if (token) {
    $api.setBearerToken(token);

    // Get initSession action from pinia store

    const authStore = useAuthStore();

    try {
      await authStore.initSession();
    } catch (err) {
      localStorage.removeItem("token");
      $api.setBearerToken(null);
    }

    // Call initSession action
  }

  // Get env from server

  const configStore = useConfigStore();

  const env = await $api.call("get", $api.route("env"));
  const pricing = await $api.call("get", $api.route("pricing"));

  configStore.$patch({ pricing });

  configStore.$patch({ env });
});
