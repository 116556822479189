import { defineNuxtPlugin } from "#app";
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import ProgressSpinner from "primevue/progressspinner";
import Dropdown from "primevue/dropdown";
import ProgressBar from "primevue/progressbar";
import Slider from "primevue/slider";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import InputMask from "primevue/inputmask";
import Panel from "primevue/panel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Chip from "primevue/chip";
import Tag from "primevue/tag";
import Skeleton from "primevue/skeleton";
import RadioButton from "primevue/radiobutton";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, {
    ripple: true,
    locale: {
      dayNames: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ],

      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      monthNames: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      monthNamesShort: [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Jui",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ],
    },
  });

  nuxtApp.vueApp.component("AutoComplete", AutoComplete);
  nuxtApp.vueApp.component("Calendar", Calendar);
  nuxtApp.vueApp.component("InputText", InputText);
  nuxtApp.vueApp.component("Message", Message);
  nuxtApp.vueApp.component("ProgressSpinner", ProgressSpinner);
  nuxtApp.vueApp.component("Dropdown", Dropdown);
  nuxtApp.vueApp.component("ProgressBar", ProgressBar);
  nuxtApp.vueApp.component("Slider", Slider);
  nuxtApp.vueApp.component("InputSwitch", InputSwitch);
  nuxtApp.vueApp.component("InputNumber", InputNumber);
  nuxtApp.vueApp.component("InputMask", InputMask);
  nuxtApp.vueApp.component("Panel", Panel);
  nuxtApp.vueApp.component("DataTable", DataTable);
  nuxtApp.vueApp.component("Column", Column);
  nuxtApp.vueApp.component("Badge", Badge);
  nuxtApp.vueApp.directive("badge", BadgeDirective);
  nuxtApp.vueApp.component("Dialog", Dialog);
  nuxtApp.vueApp.component("Button", Button);
  nuxtApp.vueApp.component("Chip", Chip);
  nuxtApp.vueApp.component("Tag", Tag);
  nuxtApp.vueApp.component("Skeleton", Skeleton);
  nuxtApp.vueApp.component("RadioButton", RadioButton);
  nuxtApp.vueApp.component("ConfirmDialog", ConfirmDialog);
  nuxtApp.vueApp.use(ConfirmationService);
});
