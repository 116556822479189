<template>
  <Transition
    style="animation-duration: 300ms"
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <site-spinner v-if="loading"></site-spinner>
  </Transition>

  <NuxtLayout name="main">
    <NuxtPage />
  </NuxtLayout>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  beforeMount() {
    this.loading = true;
  },
  mounted() {
    this.loading = false;
  },
};
</script>

<script setup>
const { $CC } = useNuxtApp();
const { gtag, revokeConsent, grantConsent } = useGtag();

function checkConsent() {
  const detail = $CC.getCookie();

  if (detail && detail.categories?.includes("analytics")) {
    grantConsent();
  } else {
    revokeConsent();
  }
}

onMounted(() => {
  const detail = $CC.getCookie();

  checkConsent();

  window.addEventListener("cc:onConsent", ({ detail }) => {
    if (detail.cookie.categories.includes("analytics")) {
      grantConsent();
    } else {
      revokeConsent();
    }
  });
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter-from,
.fade-leave-to {
  filter: grayscale(1);
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
