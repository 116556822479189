<template>
  <div
    class="bg-secondary fixed bottom-0 left-0 right-0 top-0 z-[1000] flex h-full w-full items-center justify-center"
  >
    <div
      class="inline-block h-32 w-32 animate-spin rounded-full border-4 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    >
      <span
        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span
      >
    </div>
  </div>
</template>
