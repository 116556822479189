import { defineStore } from "pinia";

export const useAuthStore = defineStore({
  id: "user",
  state: () => ({
    me: null,
    company: null,
    notifications: {},
  }),
  getters: {
    isAuthenticated(state) {
      return !!state.me && !!state.company;
    },
  },
  actions: {
    async refresh() {
      const { $api } = useNuxtApp();
      const res = await $api.call("post", $api.route("auth.refresh"));
      return res;
    },
    async updateMe(data) {
      const { $api } = useNuxtApp();
      const me = await $api.call("put", $api.route("auth.me"), data);
      this.$patch({ me: me });
      return me;
    },

    async logout() {
      const { $api, $router } = useNuxtApp();
      this.$patch({ me: null });
      localStorage.removeItem("token");
      $api.setBearerToken(null);
      $router.push("/auth/login");
    },
    async fetchMe() {
      const { $api } = useNuxtApp();
      const me = await $api.call("get", $api.route("auth.me"));
      this.$patch({ me });
      return me;
    },
    async fetchNotifications() {
      const { $api } = useNuxtApp();

      const notifications = await $api.call(
        "get",
        $api.route("me.company.notifications")
      );

      this.$patch({ notifications });
      return notifications;
    },

    async fetchCompany() {
      const { $api } = useNuxtApp();
      const company = await $api.call("get", $api.route("me.company"));
      this.$patch({ company });
      return company;
    },

    async initSession() {
      const { $api, $router } = useNuxtApp();

      const token = localStorage.getItem("token");
      if (token) {
        $api.setBearerToken(token);
      }

      try {
        const me = await this.fetchMe();
        await this.fetchCompany();
        return me;
      } catch (err) {
        console.error(err);
        throw new Error(
          "Un problème est survenu lors de la récupération de votre profil"
        );
      }
    },
  },
});
