import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import type { CookieConsentConfig } from "vanilla-cookieconsent";

const config: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom right",
    },
    preferencesModal: {
      layout: "box",
    },
  },

  onFirstConsent: () => {},

  onConsent: () => {},

  onChange: () => {},

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid|_ga_6SYFTJ34QS)/,
          },
        ],
      },
    },
    ads: {},
  },

  language: {
    default: "en",

    translations: {
      en: {
        consentModal: {
          title: "Bonjour cher visiteur ! C'est le moment de parler cookies 🍪",
          description:
            "Notre site utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Ces derniers seront définis uniquement après consentement.",
          acceptAllBtn: "Accepter tout",
          acceptNecessaryBtn: "Accepter les nécessaires",
          showPreferencesBtn: "Préférences",
          footer: `
            <a href="/politique-cookie">Politique cookies</a>
          `,
        },
        preferencesModal: {
          title: "Préférences de cookies",
          acceptAllBtn: "Accepter tout",

          acceptNecessaryBtn: "Accepter les nécessaires",
          savePreferencesBtn: "Sauvegarder les préférences",
          sections: [
            {
              title: "Liste des cookies",
              description:
                "Vous pouvez consulter ci-dessous la liste des cookies utilisés sur notre site et les activer ou désactiver selon vos préférences.",
            },
            {
              title: "Cookies strictement nécessaires",
              description:
                "Ces cookies sont nécessaires au bon fonctionnement du site et ne peuvent pas être désactivés.",
              linkedCategory: "necessary",
              cookieTable: {
                headers: {
                  name: "Cookie",
                  domain: "Domain",
                  desc: "Description",
                },
                body: [
                  {
                    name: "_stripe_mid",
                    domain: "stripe.com",
                    desc: "Assure la prévention de fraude.(Stripe)",
                  },
                  {
                    name: "_stripe_sid",
                    domain: "stripe.com",
                    desc: "Assure la prévention de fraude.(Stripe)",
                  },
                  {
                    name: "cc_cookie",
                    domain: "funargos.fr",
                    desc: "Pour enregistrer les préférences de cookies.",
                  },
                ],
              },
            },
            {
              title: "Analyse et suivi",
              description:
                "Ces cookies nous permettent de mesurer le trafic et d'analyser le comportement des visiteurs du site de façon anonyme.",
              linkedCategory: "analytics",
              cookieTable: {
                headers: {
                  name: "Cookie",
                  domain: "Domain",
                  desc: "Description",
                },
                body: [
                  {
                    name: "_ga",
                    domain: "google.com",
                    desc: "Stocke et compte les pages vues.(Google Analytics)",
                  },
                  {
                    name: "_ga_6SYFTJ34QS",
                    domain: "google.com",
                    desc: "Identifie de façon unique les utilisateurs. Collecte des stats.(Google Analytics)",
                  },
                ],
              },
            },

            {
              title: "Plus d'informations",
              description: `Pour plus d'informations sur l'utilisation des cookies, vous pouvez nous contacter à l'adresse suivante : <a href="mailto:contact@funargos.fr">contact@funargos.fr</a>`,
            },
          ],
        },
      },
    },
  },
};

export default defineNuxtPlugin(async () => {
  await CookieConsent.run(config);

  return {
    provide: {
      CC: CookieConsent,
    },
  };
});
