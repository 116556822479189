import { createI18n } from "vue-i18n";

export default defineNuxtPlugin(({ vueApp }) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "fr",
    numberFormats: {
      fr: {
        currency: {
          style: "currency",
          currency: "EUR",
        },
        decimal: {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
        percent: {
          style: "percent",
        },
      },
    },
    messages: {
      fr: {},
    },
  });

  vueApp.use(i18n);
});
