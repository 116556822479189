import { default as forgot_45passwordL8IOPRp811Meta } from "/home/ubuntu/funargos/frontend/pages/auth/forgot-password.vue?macro=true";
import { default as login70UrEZZ0vMMeta } from "/home/ubuntu/funargos/frontend/pages/auth/login.vue?macro=true";
import { default as registerr90h1md5JHMeta } from "/home/ubuntu/funargos/frontend/pages/auth/register.vue?macro=true";
import { default as cgut6v1bIGVw9Meta } from "/home/ubuntu/funargos/frontend/pages/cgu.vue?macro=true";
import { default as indexvqVGZQ3LNHMeta } from "/home/ubuntu/funargos/frontend/pages/dashboard/index.vue?macro=true";
import { default as mes_45alertesjVI5SR6MPDMeta } from "/home/ubuntu/funargos/frontend/pages/dashboard/mes-alertes.vue?macro=true";
import { default as mes_45demandes_45de_45trajetsTxCc0Zm0HOMeta } from "/home/ubuntu/funargos/frontend/pages/dashboard/mes-demandes-de-trajets.vue?macro=true";
import { default as mes_45trajets_45achetesdjPjLG4HUXMeta } from "/home/ubuntu/funargos/frontend/pages/dashboard/mes-trajets-achetes.vue?macro=true";
import { default as mes_45trajets_45publiesZlCifMJZlUMeta } from "/home/ubuntu/funargos/frontend/pages/dashboard/mes-trajets-publies.vue?macro=true";
import { default as mes_45transports_45acheteslLcYKf8ffWMeta } from "/home/ubuntu/funargos/frontend/pages/dashboard/mes-transports-achetes.vue?macro=true";
import { default as dashboardWGHbSaOaZwMeta } from "/home/ubuntu/funargos/frontend/pages/dashboard.vue?macro=true";
import { default as guideb4k22BZp4TMeta } from "/home/ubuntu/funargos/frontend/pages/guide.vue?macro=true";
import { default as indexwsTdqsTNvqMeta } from "/home/ubuntu/funargos/frontend/pages/index.vue?macro=true";
import { default as less_45demandesUfwwgzmW3FMeta } from "/home/ubuntu/funargos/frontend/pages/less-demandes.vue?macro=true";
import { default as mentions_45legalesp2dgqgWyPNMeta } from "/home/ubuntu/funargos/frontend/pages/mentions-legales.vue?macro=true";
import { default as nos_45servicesxcwFjkONweMeta } from "/home/ubuntu/funargos/frontend/pages/nos-services.vue?macro=true";
import { default as notre_45conceptY5J8xjZTEyMeta } from "/home/ubuntu/funargos/frontend/pages/notre-concept.vue?macro=true";
import { default as politique_45cookie1XGTdx3Ye4Meta } from "/home/ubuntu/funargos/frontend/pages/politique-cookie.vue?macro=true";
import { default as politique_45de_45confidentialiteDC7wrzS8oBMeta } from "/home/ubuntu/funargos/frontend/pages/politique-de-confidentialite.vue?macro=true";
import { default as publier_45un_45trajet6HybDsIUToMeta } from "/home/ubuntu/funargos/frontend/pages/publier-un-trajet.vue?macro=true";
import { default as rechercher_45un_45trajetNjVjRkxy6NMeta } from "/home/ubuntu/funargos/frontend/pages/rechercher-un-trajet.vue?macro=true";
import { default as resultatsqbOeLPhCyMMeta } from "/home/ubuntu/funargos/frontend/pages/resultats.vue?macro=true";
import { default as votre_45un_45trajetLG3XjBArVkMeta } from "/home/ubuntu/funargos/frontend/pages/votre-un-trajet.vue?macro=true";
export default [
  {
    name: forgot_45passwordL8IOPRp811Meta?.name ?? "auth-forgot-password",
    path: forgot_45passwordL8IOPRp811Meta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordL8IOPRp811Meta || {},
    alias: forgot_45passwordL8IOPRp811Meta?.alias || [],
    redirect: forgot_45passwordL8IOPRp811Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: login70UrEZZ0vMMeta?.name ?? "auth-login",
    path: login70UrEZZ0vMMeta?.path ?? "/auth/login",
    meta: login70UrEZZ0vMMeta || {},
    alias: login70UrEZZ0vMMeta?.alias || [],
    redirect: login70UrEZZ0vMMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registerr90h1md5JHMeta?.name ?? "auth-register",
    path: registerr90h1md5JHMeta?.path ?? "/auth/register",
    meta: registerr90h1md5JHMeta || {},
    alias: registerr90h1md5JHMeta?.alias || [],
    redirect: registerr90h1md5JHMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: cgut6v1bIGVw9Meta?.name ?? "cgu",
    path: cgut6v1bIGVw9Meta?.path ?? "/cgu",
    meta: cgut6v1bIGVw9Meta || {},
    alias: cgut6v1bIGVw9Meta?.alias || [],
    redirect: cgut6v1bIGVw9Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/cgu.vue").then(m => m.default || m)
  },
  {
    path: dashboardWGHbSaOaZwMeta?.path ?? "/dashboard",
    children: [
  {
    name: indexvqVGZQ3LNHMeta?.name ?? "dashboard",
    path: indexvqVGZQ3LNHMeta?.path ?? "",
    meta: indexvqVGZQ3LNHMeta || {},
    alias: indexvqVGZQ3LNHMeta?.alias || [],
    redirect: indexvqVGZQ3LNHMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: mes_45alertesjVI5SR6MPDMeta?.name ?? "dashboard-mes-alertes",
    path: mes_45alertesjVI5SR6MPDMeta?.path ?? "mes-alertes",
    meta: mes_45alertesjVI5SR6MPDMeta || {},
    alias: mes_45alertesjVI5SR6MPDMeta?.alias || [],
    redirect: mes_45alertesjVI5SR6MPDMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/dashboard/mes-alertes.vue").then(m => m.default || m)
  },
  {
    name: mes_45demandes_45de_45trajetsTxCc0Zm0HOMeta?.name ?? "dashboard-mes-demandes-de-trajets",
    path: mes_45demandes_45de_45trajetsTxCc0Zm0HOMeta?.path ?? "mes-demandes-de-trajets",
    meta: mes_45demandes_45de_45trajetsTxCc0Zm0HOMeta || {},
    alias: mes_45demandes_45de_45trajetsTxCc0Zm0HOMeta?.alias || [],
    redirect: mes_45demandes_45de_45trajetsTxCc0Zm0HOMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/dashboard/mes-demandes-de-trajets.vue").then(m => m.default || m)
  },
  {
    name: mes_45trajets_45achetesdjPjLG4HUXMeta?.name ?? "dashboard-mes-trajets-achetes",
    path: mes_45trajets_45achetesdjPjLG4HUXMeta?.path ?? "mes-trajets-achetes",
    meta: mes_45trajets_45achetesdjPjLG4HUXMeta || {},
    alias: mes_45trajets_45achetesdjPjLG4HUXMeta?.alias || [],
    redirect: mes_45trajets_45achetesdjPjLG4HUXMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/dashboard/mes-trajets-achetes.vue").then(m => m.default || m)
  },
  {
    name: mes_45trajets_45publiesZlCifMJZlUMeta?.name ?? "dashboard-mes-trajets-publies",
    path: mes_45trajets_45publiesZlCifMJZlUMeta?.path ?? "mes-trajets-publies",
    meta: mes_45trajets_45publiesZlCifMJZlUMeta || {},
    alias: mes_45trajets_45publiesZlCifMJZlUMeta?.alias || [],
    redirect: mes_45trajets_45publiesZlCifMJZlUMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/dashboard/mes-trajets-publies.vue").then(m => m.default || m)
  },
  {
    name: mes_45transports_45acheteslLcYKf8ffWMeta?.name ?? "dashboard-mes-transports-achetes",
    path: mes_45transports_45acheteslLcYKf8ffWMeta?.path ?? "mes-transports-achetes",
    meta: mes_45transports_45acheteslLcYKf8ffWMeta || {},
    alias: mes_45transports_45acheteslLcYKf8ffWMeta?.alias || [],
    redirect: mes_45transports_45acheteslLcYKf8ffWMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/dashboard/mes-transports-achetes.vue").then(m => m.default || m)
  }
],
    name: dashboardWGHbSaOaZwMeta?.name ?? undefined,
    meta: dashboardWGHbSaOaZwMeta || {},
    alias: dashboardWGHbSaOaZwMeta?.alias || [],
    redirect: dashboardWGHbSaOaZwMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: guideb4k22BZp4TMeta?.name ?? "guide",
    path: guideb4k22BZp4TMeta?.path ?? "/guide",
    meta: guideb4k22BZp4TMeta || {},
    alias: guideb4k22BZp4TMeta?.alias || [],
    redirect: guideb4k22BZp4TMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: indexwsTdqsTNvqMeta?.name ?? "index",
    path: indexwsTdqsTNvqMeta?.path ?? "/",
    meta: indexwsTdqsTNvqMeta || {},
    alias: indexwsTdqsTNvqMeta?.alias || [],
    redirect: indexwsTdqsTNvqMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: less_45demandesUfwwgzmW3FMeta?.name ?? "less-demandes",
    path: less_45demandesUfwwgzmW3FMeta?.path ?? "/less-demandes",
    meta: less_45demandesUfwwgzmW3FMeta || {},
    alias: less_45demandesUfwwgzmW3FMeta?.alias || [],
    redirect: less_45demandesUfwwgzmW3FMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/less-demandes.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesp2dgqgWyPNMeta?.name ?? "mentions-legales",
    path: mentions_45legalesp2dgqgWyPNMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesp2dgqgWyPNMeta || {},
    alias: mentions_45legalesp2dgqgWyPNMeta?.alias || [],
    redirect: mentions_45legalesp2dgqgWyPNMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: nos_45servicesxcwFjkONweMeta?.name ?? "nos-services",
    path: nos_45servicesxcwFjkONweMeta?.path ?? "/nos-services",
    meta: nos_45servicesxcwFjkONweMeta || {},
    alias: nos_45servicesxcwFjkONweMeta?.alias || [],
    redirect: nos_45servicesxcwFjkONweMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/nos-services.vue").then(m => m.default || m)
  },
  {
    name: notre_45conceptY5J8xjZTEyMeta?.name ?? "notre-concept",
    path: notre_45conceptY5J8xjZTEyMeta?.path ?? "/notre-concept",
    meta: notre_45conceptY5J8xjZTEyMeta || {},
    alias: notre_45conceptY5J8xjZTEyMeta?.alias || [],
    redirect: notre_45conceptY5J8xjZTEyMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/notre-concept.vue").then(m => m.default || m)
  },
  {
    name: politique_45cookie1XGTdx3Ye4Meta?.name ?? "politique-cookie",
    path: politique_45cookie1XGTdx3Ye4Meta?.path ?? "/politique-cookie",
    meta: politique_45cookie1XGTdx3Ye4Meta || {},
    alias: politique_45cookie1XGTdx3Ye4Meta?.alias || [],
    redirect: politique_45cookie1XGTdx3Ye4Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/politique-cookie.vue").then(m => m.default || m)
  },
  {
    name: politique_45de_45confidentialiteDC7wrzS8oBMeta?.name ?? "politique-de-confidentialite",
    path: politique_45de_45confidentialiteDC7wrzS8oBMeta?.path ?? "/politique-de-confidentialite",
    meta: politique_45de_45confidentialiteDC7wrzS8oBMeta || {},
    alias: politique_45de_45confidentialiteDC7wrzS8oBMeta?.alias || [],
    redirect: politique_45de_45confidentialiteDC7wrzS8oBMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: publier_45un_45trajet6HybDsIUToMeta?.name ?? "publier-un-trajet",
    path: publier_45un_45trajet6HybDsIUToMeta?.path ?? "/publier-un-trajet",
    meta: publier_45un_45trajet6HybDsIUToMeta || {},
    alias: publier_45un_45trajet6HybDsIUToMeta?.alias || [],
    redirect: publier_45un_45trajet6HybDsIUToMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/publier-un-trajet.vue").then(m => m.default || m)
  },
  {
    name: rechercher_45un_45trajetNjVjRkxy6NMeta?.name ?? "rechercher-un-trajet",
    path: rechercher_45un_45trajetNjVjRkxy6NMeta?.path ?? "/rechercher-un-trajet",
    meta: rechercher_45un_45trajetNjVjRkxy6NMeta || {},
    alias: rechercher_45un_45trajetNjVjRkxy6NMeta?.alias || [],
    redirect: rechercher_45un_45trajetNjVjRkxy6NMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/rechercher-un-trajet.vue").then(m => m.default || m)
  },
  {
    name: resultatsqbOeLPhCyMMeta?.name ?? "resultats",
    path: resultatsqbOeLPhCyMMeta?.path ?? "/resultats",
    meta: resultatsqbOeLPhCyMMeta || {},
    alias: resultatsqbOeLPhCyMMeta?.alias || [],
    redirect: resultatsqbOeLPhCyMMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/resultats.vue").then(m => m.default || m)
  },
  {
    name: votre_45un_45trajetLG3XjBArVkMeta?.name ?? "votre-un-trajet",
    path: votre_45un_45trajetLG3XjBArVkMeta?.path ?? "/votre-un-trajet",
    meta: votre_45un_45trajetLG3XjBArVkMeta || {},
    alias: votre_45un_45trajetLG3XjBArVkMeta?.alias || [],
    redirect: votre_45un_45trajetLG3XjBArVkMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/funargos/frontend/pages/votre-un-trajet.vue").then(m => m.default || m)
  }
]