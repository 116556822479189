import { defineStore } from "pinia";

export const useConfigStore = defineStore({
  id: "config",
  state: () => ({
    env: {},
    pricing: {},
  }),
  getters: {},
  actions: {},
});
